import { LOCALES } from './locales'

export const messages = {
  [LOCALES.ENGLISH]: {
    // Сообщения
    // Общие ошибки
    message_400: 'Bad request',
    message_401: 'Login failed. Check  the details you entered are correct or try again later.',
    message_402: 'Payment Required',
    message_403: 'Access denied',
    message_404: 'Not found',
    message_500: 'Something has gone wrong.\n' + 'Please refresh the page.',
    // Авторизация
    message_login_success: 'Hello! You are successfully logged in as ',
    message_login_error:
      'Login failed. Check that the details you entered are correct or try again later.',
    message_token_fail:
      'The authorisation time has expired.\n' + 'Please log in to your profile again.',
    // Выход из учетной записи
    message_logout: 'Do you really want to logout?',
    // Регистрация
    message_register_success: 'Congratulations! You have successfully registered on Lebao Ba!',
    message_register_error:
      'Oops! Registration failed.\n' +
      'Check that the details you entered are correct or try again later.',
    // Школа
    message_school_payment_required:
      'There are not enough coins to purchase.\n' + 'Please top up and try again.',
    message_school_category_notFound: 'Category is empty.',
    // Спорт
    message_sport_cart_clear: 'Basket is empty',
    // Аналитика
    message_sport_analytic_payment_required:
      'There are not enough coins to purchase.\n' + 'Please top up and try again.',
    message_sport_analytic_success_payment: 'Purchase successfully',
    lebao_app: 'Lebao ba App!',
    title_register: 'Fill out the registration form',
    accept_terms_conditions: 'I accept the terms and conditions',
    yes: 'Yes',
    no: 'No',
    confirm_message:
      'Are you ready to take an oath and confirm that the testimony... are they truthful and accurate based on what you know ?',
    phone: 'Phone',
    from: 'From',
    to: 'To',
    country: 'Country',
    select_country: 'Select country',
    select_phone_number: 'Select a phone number',
    login_email: 'Email address',
    user_id_email: 'Email or user id ',
    login_email_code: 'Email address',
    login_code: 'Activation code',
    login_pass: 'Password',
    login_pass_new: 'New password',
    login_pass_repeat: 'Password repeat',
    login: 'Login',
    log_in: 'Log in',
    login_account: 'Login to your personal account',
    login_text: 'To get full access to all the functions of the service',
    verify: 'Verify',
    unverified_mail: 'Unverified mail',
    verify_mail: 'You need to enter your email and verify',
    to_go_settings: 'Do you want to go to settings?',
    go_setting: 'Go to settings',
    settings: 'Settings',
    register: 'Registration',
    forgot_pass: 'Forgot password?',
    reset_pass: 'Reset password',
    submit: 'Submit',
    confirm: 'Confirm',
    confirm_buy: 'This section is exclusive, unblocking it will cost you ',
    withdrawal_buy: 'You are trying to withdraw your funds in the amount of ',
    confirm_buy_two: 'Do you confirm this purchase?',
    confirm_withdrawal: 'Do you confirm this withdrawal?',
    potential_winnings: 'Potential winnings:',
    have_account: 'Do you have an account?',
    have_not_account: 'Don’t you have an account?',
    repeatCode: 'Resend code',
    send: 'Send',
    second: 'sec',
    have_code: 'Do you have the confirmation code?',
    message_code:
      'You specified an incorrect e-mail or the letter did not arrive at the post office?',
    sendActivationCode: 'We have sent the activation code to',
    code_info:
      'You specified an incorrect email address or the email did not arrive at the mailbox?',
    checkedEmail: 'Check your mailbox',
    right_forecasts: 'Right forecasts',
    header_menu_sports: 'Sports',
    header_sports_sub_menu_match_centre: 'Match centre',
    header_sports_sub_menu_analytics: 'Analytics',
    header_experts_sub_menu_analytics: 'Experts',
    header_sports_sub_menu_blog_news: 'News',
    header_sports_sub_menu_blog_school: 'School',
    header_menu_game: 'Games',
    header_menu_expert: 'Experts',
    header_menu_languages: 'Languages',
    header_menu_game_search: 'Search for games',
    header_menu_Blog: 'Blog',
    header_menu_shop: 'Shop',
    read_more_text: 'Read more',
    header_menu_leaderboard: 'Leaderboard',
    match_centre_title: 'Match centre',
    match_centre_description:
      'Welcome to our Sports Betting Line Section, where action meets opportunity! Here, you can find the latest odds on a wide array of sporting events from around the world. Dive into up-to-date stats, analyses, and predictions, and place your bets with confidence. Always remember to gamble responsibly and enjoy the thrill of the game!',
    analytics_title: 'Analytics from professionals',
    experts_description: `The higher your status in the reward program, the more specialists you will be able to discover. At the moment, you have access to Level 1 experts. You can purchase an article or a forecast from an expert.`,
    experts_levels: 'Levels',
    expert_since: 'Expert since',
    expert_years: 'Years of experience',
    analytics_description:
      'This analytical section will provide you not only the results of recent matches, but also a detailed statistic of previous meetings with a period of as many as 10 years! Just select the sport, league and 2 teams, and the information page will be generated independently.',
    leaderboard_title: 'Leaderboard',
    leaderboard_description: `Here you will find the best players on our site!`,
    leaderboard_description_sub: `But remember: your ranking will be renewed every time, so keep going and find yourself among the best!`,
    game_title: 'Virtual sports',
    game_description: `Check out our range of slots. Take a spin and play for a chance to win big! Whether you're a beginner or a seasoned player, there's something for everyone. Experience the thrill and see if luck is on your side!`,
    Blog_title: 'Betting Blog',
    Blog_description:
      'Welcome to Lebao blog! Here, we share all the latest from the world of sports betting. Get updates on recent game results, player news, and hot betting tips. Plus, check out our Betting School, where beginners can learn the basics and experts can refine their strategies. With easy-to-read updates and lessons, we are here to help you stay informed and bet smarter.',
    shop_title: 'Shop',
    shop_description: 'Bet, accumulate betcoins, come to our gift shop and collect prizes!',
    betting_title: 'Betting School',
    betting_description:
      'Welcome to the Lebao Ba School! Here you can learn everything about slots, live casino, video games, sports betting, sports and handicap analysis, as well as all the rules and practical skills ofbetting! With a short learning period and a wide range',
    betting_description_tablet:
      'Welcome to the Lebao Ba School! Here you can learn everything about slots, live casinos, video games, sports betting, sports and handicap analysis, as well as all the rules and practical skills of betting!',

    bronze_level: 'Bronze Level',
    silver_level: 'Silver Level',
    gold_level: 'Gold Level',
    platinum_level: 'Platinum Level',
    required_level_start: 'A',
    required_level: 'is required for access',
    forCasts: 'Forcasts',
    delete_all: 'Delete all',
    profile_title: 'Profile',
    profile_description: ' ',
    download_app: 'DOWNLOAD THE APP',
    languages: 'Languages',
    chinese: '中文',
    english: 'English',
    main: 'Main',
    profile: 'Profile',
    logout: 'Logout',
    stay: 'Stay',
    edit_data: 'Edit Data',
    change_password: 'Change Password',
    payment_history: 'Payment history',
    betting_history: 'Betting history',
    deposit_history: 'Deposit',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    promoCode: 'Promo code',
    promoCodeSendMail: 'This promo code has been sent to your email',
    promoCodeCopy: 'Copy the code',
    searchPlaceholder: 'Start searching...',
    searchGame: 'Game search',
    searchGameDescription: 'Enter the name of the game completely or partially.',
    searchGameDescription_2: 'Key words must be entered correctly',
    not_found: 'Not Found...',
    information: 'INFORMATION',
    success: 'SUCCESS',
    error: 'ERROR',
    copy_wechat: 'Wechat id copied to clipboard',
    close: 'Close',
    view_details: 'View details',
    detail: 'Detail',
    price: 'Price',
    id: 'ID',
    name: 'Name',
    summ: 'Summ',
    date: 'Date',
    conclusion: 'Conclusion',
    product: 'Product',
    description: 'Description',
    all_category: 'All category',
    basket_bets: 'Betting Slip',
    not_coins: 'Not enough coins...',
    excellent: 'Excellent!',
    excellent_description: `Based on your answers, we suggest that you may want to start with a
						little game to win some extra bonuses (up to betcoins). Click
						‘collect’ to try your luck!`,

    bonuses_to_account: 'We just credited <span>1000 betcoins</span> to your account!',
    bonuses_to_account_start: 'We just credited ',
    bonuses_to_account_end: 'to your account!',
    continue_luck: `Continue your luck using the login and password that we prepared for you:`,
    bet_slip: 'Bet slip',
    bet_types: 'Bet type',
    my_bets: 'My bets',
    loader: 'LOADING ...',
    clear: 'Clear',
    over_all_odds: 'Overall odds',
    stake: 'Stake',
    accumulator: 'Accumulator',
    singles: 'Singles',
    system_bet: 'System bet',
    settings_stake_info: 'Set bet slip stake to change by',
    stake_amount: 'Stake amount',
    possible_win: 'Possible win',
    agree_bet: 'Agree to change the coefficient',
    bet: 'Bet',
    place_bet: 'Place a bet',
    place_bid: 'Place a bid of',
    unknown: 'Unknown',
    bets_not_accepted: 'Bets on this event are not accepted!',
    unlock: 'Unlock',
    home_route: 'Home',
    experts_route: 'Experts',
    sports_route: 'Sports',
    'match-centre_route': 'Match centre',
    analytics_route: 'Analytics',
    tournament_route: 'Tournament',
    school_route: 'Betting School',
    news_route: 'News',
    game_route: 'Game',
    forCasts_route: 'Forcasts',
    games_route: 'Games',
    blog_route: 'Blog',
    sport_route: 'Sports',
    category_route: 'Category',
    slots_route: 'Slots',
    item_route: 'More detailed',
    shop_route: 'Shop',
    leaderboard_route: 'Leaderboard',
    //Errors form
    required_field: 'Required field',
    correct_email: 'Enter a valid email address',
    correct_email_user_id: 'Enter a valid email address or user ID ',
    correct_login: 'Enter a valid login',
    correct_phone: 'Enter a valid phone',
    correct_password: 'Enter a valid password',
    correct_password_repeat: `Passwords don't match`,
    correct_length: `Enter the correct length`,
    minimum_characters: 'The minimum number of characters',
    maximum_characters: 'The maximum number of characters',

    'User not found': 'User not found',
    'Code is not valid': 'Code is not valid',
    'Email Used': 'Email used',
    'Wrong password': 'Wrong password',
    'User already exists': 'User already exists',
    'Network Error': 'Network Error',
    'Express Must be 2 or more bets': `Express Must be 2 or more bets`,

    error_page: 'Page Error',
    menu_name: 'Menu',
    coupon_name: 'Coupon',
    best_slit_name: 'Best slip',
    profile_route: 'Account',
    main_route: 'Main',
    'edit-profile_route': 'Edit Profile',
    'account-profile_route': 'Account',
    'edit-info_route': 'Edit info',
    'change-password_route': 'Change password',
    'payment-history_route': 'Payment history',
    'betting-history_route': 'Betting history',
    'deposit-history_route': 'Deposit ',
    'bet-dictionary_route': 'Dictionary',
    lebao_foundation_route: 'Lebao ba Foundation',
    works_route: 'Works',
    bonuses_route: 'Bonuses',
    'privacy-policy_route': 'Privacy Policy',
    'gamble-responsible_route': 'Gamble Responsible',
    'terms-and-conditions_route': 'Terms and Conditions',
    faq_route: 'FAQ',
    open_for: 'Open for: ',
    read_more: 'See forcast',
    your_bet: 'Your bet',
    blocked: 'BLOCKED',
    BET_IN_PROGRESS: 'PROGRESS',
    BET_IS_RETURN: 'REFUND',
    BET_IS_REFUND_ERROR: 'MONEY BACK',
    HALF_REFUND: `HALF THE MONEY'S BACK`,
    BET_IS_WIN: 'WON',
    BET_IS_LOSE: 'LOST',
    BET_IS_CANCELED: 'BET IS CANCELED',
    BET_IS_DELETED: 'BET IS DELETED',
    possible_winnings: 'Possible winning',
    winnings: 'Winnings',
    status: 'Status',
    section: 'Section',

    cancel: 'Cancel',
    ok: 'OK',
    apply: 'Apply',
    next: 'Next',
    save: 'Save',
    save_editor: 'Save editing',
    save_password: 'Save password',
    change_info: 'Change info',
    express: 'Accumulator',
    odinar: 'Single Bet',
    result: 'Result',
    event: 'Event',
    event_status: 'Event Status',
    express_game_title: 'Express Game',
    win: 'Win',
    W1: 'W1',
    W1_way: 'W1 (2way)',
    X: 'X',
    W2: 'W2',
    W2_way: 'W2 (2way)',
    '1X': '1X',
    '12': '12',
    '2X': '2X',
    total: 'Total',
    total_over: 'Total Over Undo',
    win_and_total: 'Win And Total',
    total_kef: 'Total coefficient',
    handicap: 'Handicap',
    handicap_sets: 'Handicap Sets',
    full_battle: 'Full battle, Yes-kf, No-kf',
    team_wins: 'Team wins',
    betsword: 'Betsword',
    betsword_discriptions:
      'Navigating the world of betting can be challenging with its unique terms. Our Betting Dictionaryis here to guide you. Knowledge is power, and we aim to empower every bettor!',
    bonuses: 'Experience system in Lebao',
    bonuses_discriptions:
      "This system allows you to analyze the player's activity and current progress. How XP is credited – players perform active actions on the site, and for each action they receive experience points.",
    page_title_not_found: 'NotFound',
    page_description_not_found: 'NotFound',
    page_title_home:
      'LeBaoba is a social gaming platform where you can place virtual sports bets on real matches around the world.',
    page_description_home:
      'Staking allows you to earn LeBaoba coins. Bets You can spend in our store or buy lottery draws Get free tips Predict to climb the leaderboard or learn and practice sports betting.',
    page_title_blog: 'Blog',
    page_description_blog: 'Sports News, Betting School',
    page_title_blog_news: 'News',
    page_description_blog_news: 'News',
    page_title_blog_school: 'School',
    school_category: 'School',
    shop: 'shop',
    analytic: 'analytic',
    completed: 'Completed',
    page_description_blog_school: 'School',
    page_title_sport: 'Competition Center',
    page_description_sport: ' ',
    page_title_sport_match_center: 'Match center',
    page_description_sport_match_center:
      'select a sport, a league and 2 teams, and the information page will be generated independently',
    page_title_sport_analytics: 'Analytics',
    page_description_sport_analytics: ' ',
    page_title_shop: 'Shop',
    page_description_shop:
      'Place bets, accumulate game coins, come to our gift shop and get prizes.',
    page_title_user: 'Account',
    page_description_user: 'Balance, Edit Data, Change Password, Payment History, Betting History',
    page_title_user_edit: 'Change Account Settings',
    page_description_user_edit: ' ',
    page_title_user_edit_password: 'Change password',
    page_description_user_edit_password: ' ',
    page_title_betsword: 'Bet dictionary',
    page_description_betsword: 'Bet dictionary',
    page_title_bonuses: 'Bonuses',
    page_description_bonuses:
      'How XP is awarded - Players perform active actions on the site, "for each action they receive experience points',
    page_title_games: 'Games',
    page_description_games:
      'This analysis section will not only provide you with the results of the most recent matches, but also provide you with detailed statistics on past matches up to 10 years.',
    page_title_faq: 'FAQ',
    page_description_faq: 'Why choose online games? Are deposits safe? What is a welcome bonus?',
    page_title_leaderboard_title: 'Leaderboard',
    page_title_leaderboard_description: 'statistics of lebao players',
    page_title_privacy_policy:
      'In order to preserve customer information, Lebaoba has always adhered to the most careful attitude and working procedures when dealing with',
    page_title_gamble_responsible: 'Gambling is just a form of entertainment',
    page_title_terms_and_conditions: 'The company accepts only registered users over the age of 18',
    footer_text:
      'Lebaoba is a social gaming platform where you can place bets on real sports matches happening worldwide. On Lebaoba, you earn Lebaoba tokens (BETcoins), which you can spend in our shop, buy certificates, get sport analytics, climb leaderboards, or learn and practice sports betting.',
    footer_label: 'Lebao ba©',
    bonus_title: 'Experience system in Lebao',
    bonus_description: `This system allows you to analyze the player's activity and current
		progress. How XP is credited – players perform active actions on the
		site, and for each action they receive experience points.`,
    betting_school: 'Betting School',
    balance: 'Balance',
    joined: 'Joined at',
    birth: 'Date of birth',
    county: 'County',
    collect: 'Collect',
    collect_free: 'Collect yor free coins',
    daily_bonuses:
      'Don’t miss your daily <span> 500 betcoins</span>, as long as you come back and check in everyday!',
    daily_bonuses_start: 'Don’t miss your daily ',
    daily_bonuses_end: ' as long as you come back and check in everyday!',

    demo_user: 'Demo user',
    regular_user: 'Regular user',
    demo_user_info:
      'If you would like a trail first before becoming a regular user, simply choose Demo user to discover all the basic features of our site. Moreover, you can receive daily bonus as long as you come by everyday.',
    demo_user_info_mobile:
      'If you care about privacy and do not like the formalities, start as a Demo user to make bets easily and instantly. Don’t forget to collect your daily bonus as well!',
    regular_user_info:
      'There are many benefits if you choose to register via email - you can collect daily bonus, receive gifts in the reward-system, and many more features to discover!',
    regular_user_info_mobile:
      'There are many benefits if you choose to register via email - you can collect daily bonus, receive gifts in the reward-system, and many more features to discover!',
    choose: 'Choose',
    choose_user_type: 'Choose your identity',

    xp_next_star: 'to the next star',
    level: 'level',
    username: 'User name',
    old_password: 'Old password',
    new_password: 'New password',
    repeat_password: 'Repeat the new password',
    fill_field: 'Fill in the field',
    passwords_dont_match: "Passwords don't match",
    date_from: 'Date from',
    date_to: 'Date to',
    corrected_date: 'Enter the correct date',
    show_all: 'Show all',
    hidden: 'Hidden',
    unRoll: 'Read more',
    roll: 'Read less',
    only_deposits: 'Only deposits',
    deposit: 'Deposit',
    only_witdrawals: 'Only witdrawals',
    withdrawal: 'Withdrawal',
    status_win: 'Status win',
    status_loss: 'Status loss',
    copy_link: 'Copy the link',
    empty_basket: `Your bet slip is empty. Add an event or select one of the options
		below`,
    expertMoreExperience: 'You need more experience to access this expert',
    //deposit payment
    depositDescription: 'Select the payment system through which you want to top up your account:',
    alipay: 'AliPay',
    weChatPlay: 'WeChat Pay',
    unionPay: 'Union Pay',

    //chat
    sendMessage: 'Type message here...',
    weOnline: 'We are online, write to us',
    haveQuestion: `Have a question?`,
    downloadDialog: 'Download dialog',
    attachFile: 'Attach a file',
    errorConnect: 'Connection error',
    rateSupport: `How would  you rate our support?`,
    position: `Position`,
    user: `User`,
    user_position: `User's position`,
    rating: `Rating`,

    //filter
    filter: 'Filter',
    sort_by: 'Sort by',
    latest: 'Latest',
    old: 'Old',

    //Referral
    referral_title: `Referral program`,
    referral_work: `How It Works:`,
    referral_invite: `Invite:`,
    referral_invite_description: `Share your unique referral link with friends`,

    referral_earn: `Earn:`,
    referral_earn_description: `Receive rewards for each friend who signs up and makes a purchase
		3`,

    referral_link: `Referral link:`,

    //quiz
    betcoins: 'betcoins',
    changeLang: 'Select the language of the quiz.',
    rewardTitle: `Congratulations! <br /> You've earned your daily reward of`,
    // Сообщения
    questionOneVarian1: `Interested in betting, but don't know where to start?`,
    questionTwoVarian1: `Always wanted to try, but a bit worried? `,
    questionThreeVarian1: `Do you want to get bonuses for free every day, so that you can try your luck? `,
    finalTextVariant1: 'Cautiousness is always a good thing, but you’re in good hands! ',
    finalText2Variant1:
      'Our team is trying their best to ensure your confidentiality while providing a thrilling experience. You can enjoy our games safely and legally, while getting 1000 free betcoins and 500 more every single day!',
    finalText3Variant1:
      'If you still need more time, our school is always the best way to get familiar with us. Put your gift coins 🎁 into use and get to know about betting!',

    questionOneVariant2: 'Have you ever tried sports betting?',
    questionTwoVariant2: 'How about improving your skills with a wonderful community?',
    questionThreeVariant2: ' Fancy to be the champion and win huge prizes?',
    finalTextVariant2:
      'We’re happy to see that you’re ready for more adventures! Our school is always a good point to start this journey. We also added 1000 betcoins to your account as a welcoming gift, and what’s more - you can collect 500 betcoins everyday!',
    finalText2Variant2:
      'When you feel more confident, don’t hesitate to try your new skills with the betcoins that we prepared for you 🎁',

    questionOneVariant3: 'Have you ever tried sports betting?',
    questionTwoVariant3: 'Want to get accurate predictions for sports matches?',
    questionThreeVariant3:
      'Do you want to get bonuses for free every day, so that you can try your luck? ',

    finalTextVariant3:
      'Thanks for your time! Now we see you already have some experience. That’s why we added 1000 betcoins to your account as a welcoming gift 🎁. 			',
    finalText2Variant3: `Don't forget to come back everyday and collect your daily 500 betcoins!`,
    finalText3Variant3:
      'Meanwhile, you can get even better by attending our betting school - you’re only one step away from being a pro!'
  },
  [LOCALES.CHINESE]: {
    // Сообщения
    // Общие ошибки
    message_400: '错误请求',
    message_401: '登录失败，请检查您输入的数据是否正确或尝试稍后重新登录。',
    message_402: '需要付款',
    message_403: '拒绝进入',
    message_404: '未找到',
    message_500: '接收服务器数据失败，请您重新刷新页面。',
    // Авторизация
    message_login_success: '您好，您已成功登录',
    message_login_error: '哎呦，您的登录失败了。请检查您的密码和用户名是否正确!',
    message_token_fail: '哎呦，您的登录超时了。请重新填写您的登录信息。',
    // Выход из учетной записи
    message_logout: '您真的要登出嗎？',
    // Регистрация
    message_register_success: '恭喜您！您已成功注册乐宝',
    message_register_error: '哎呦，您的注册失败了。请您重新检查您所输入的注册信息。',
    // Школа
    message_school_payment_required: '您的余额不足，请您充值后进行购买。',
    message_school_category_notFound: '类别为空。',
    // Спорт
    message_sport_cart_clear: '清空垃圾箱',
    // Аналитика
    message_sport_analytic_payment_required: '您的余额不足，请您充值后进行购买。',
    message_sport_analytic_success_payment: '购买成功！',
    lebao_app: '乐宝吧 应用程序!',
    title_register: '填写注册信息',
    accept_terms_conditions: '我接受条款和条件',
    yes: '是',
    no: '没有',
    confirm_message: '你是否准备好宣誓并确认证词......根据你所了解的情况，证词是否真实准确？',
    phone: '电话',
    from: '来自',
    to: '至',
    country: '国家',
    select_country: '选择国家',
    select_phone_number: '选择电话号码',
    login_email: '邮箱登录',
    user_id_email: '电子邮件或用户 ID ',
    login_email_code: '输入您的电子邮件地址',
    login_code: '输入激活码',
    login_pass: '登陆密码',
    login_pass_new: '新密码',
    login_pass_repeat: '重复密码',
    login: '登录',
    log_in: '登录',
    login_account: '登录您的账户',
    login_text: '全面使用服务的所有功能',
    verify: '核对',
    unverified_mail: '未经核实的邮件',
    verify_mail: '您需要输入您的电子邮件并验证',
    to_go_settings: '你想进行设置吗？',
    go_setting: '转到设置',
    settings: '设置',
    register: '注册',
    forgot_pass: '忘记密码',
    reset_pass: '重置密码',
    submit: '提交',
    confirm: '确认',
    confirm_buy: '解锁这次分析结果需要花费 ',
    confirm_buy_two: '是否确认购买?',
    potential_winnings: '可赢金额:',
    have_account: '你有帐户吗？',
    have_not_account: '你没有帐户吗？',
    repeatCode: '重新傳送代碼',
    send: '發送',
    second: '第二',
    have_code: '我已经有了代码',
    message_code: '是您指定的电子邮件不正确，还是信件没有到达邮局？',
    sendActivationCode: '我们已将激活码发送至',
    code_info: '是您指定的电子邮件地址不正确，还是电子邮件没有到达邮箱？',
    checkedEmail: '查看您的邮箱',
    right_forecasts: '正确的预测',
    header_menu_sports: '体育博彩',
    header_sports_sub_menu_match_centre: '赛事中心',
    header_sports_sub_menu_analytics: '赛事预测',
    header_experts_sub_menu_analytics: '专家',
    header_sports_sub_menu_blog_news: '新闻',
    header_sports_sub_menu_blog_school: '学校',
    header_menu_game: '娱乐场',
    header_menu_expert: '專家',
    header_menu_languages: '语言',
    header_menu_game_search: '搜索游戏',
    header_menu_Blog: '博彩博客',
    header_menu_shop: '商店',
    header_menu_leaderboard: '排行榜',
    match_centre_title: '赛事中心',
    match_centre_description:
      '欢迎来到我们的体育博彩区，这里是行动与机会的结合之地！在这里，您可以找到来自世界各地的各种体育赛事的最新赔率。深入了解最新的统计数据、分析和预测，并放心地下注。请始终记住要负责任地赌博并享受比赛的刺激！',
    experts_description: `您在獎勵計劃中的地位越高，就能發掘更多專家。目前，您可以接觸到 1 級專家。您可以向專家購買文章或預測。`,
    experts_levels: '水平',
    expert_since: '专家，因为',
    expert_years: '多年的经验',
    analytics_title: '专业人士的分析',
    read_more_text: '阅读更多',
    analytics_description:
      '这个分析部分不仅会为您提供最近比赛的结果，还会为您提供长达10年的历次比赛的详细统计信息。',
    leaderboard_title: '排行榜',
    leaderboard_description: '在这里，你会看到所有玩家的最新实时排名！',
    leaderboard_description_sub: '不过，排行榜每次都会更新，所以用不了多久，你就能赶上前几名！',
    game_title: '电子娱乐场',
    game_description:
      '探索我们的老虎机游戏系列。转动，玩耍并有机会赢得大奖！无论您是新手还是经验丰富的玩家，这里都有适合每个人的东西。体验这激动人心的感觉，看看运气是否站在您这边！',
    Blog_title: '博彩博客',
    Blog_description:
      '欢迎来到Lebao博客！在这里，我们分享有关体育博彩世界的最新信息。获取有关最近比赛结果、球员新闻和热门投注技巧的更新。此外，查看我们的投注学校，初学者可以在那里学习基础知识，而专家可以完善他们的策略。凭借易于阅读的更新和教程，我们在这里帮助您保持资讯并更智能地投注。',
    shop_title: '商店',
    shop_description: '下注，累积投注币，来我们的礼品店领取奖品！',
    betting_title: '博彩学校',
    betting_description:
      '欢迎来到 乐宝 吧 学校！在这里，您可以学到有关老虎机、真人娱乐场、视频游戏、体育博彩、体育和盘口分析的所有知识，以及博彩的所有规则和实用技巧！学习时间短，学习范围广',
    betting_description_tablet:
      '欢迎来到 Le bao Ba 学校！在这里，您可以学到有关老虎机、真人赌场、视频游戏、体育投注、体育和盘口分析的所有知识，以及所有投注规则和实用技巧！',
    forCasts: '預測',
    delete_all: '全部删除',
    bronze_level: '銅級',
    silver_level: '銀級',
    gold_level: '金級',
    required_level_start: '必須達到',
    required_level: '才能進入',
    profile_title: '个人资料',
    profile_description: '个人简介',
    download_app: '下载应用程序',
    languages: '语言',
    chinese: '中文',
    english: 'English',
    main: '主要',
    profile: '个人资料',
    logout: '登出',
    stay: '停留',
    edit_profile: '编辑简介',
    edit_data: '编辑数据',
    change_password: '更改密码',
    payment_history: '资金流水',
    betting_history: '投注历史',
    deposit_history: '存款',
    january: '一月',
    february: '二月',
    march: '三月',
    april: '四月',
    may: '五月',
    june: '六月',
    july: '七月',
    august: '八月',
    september: '九月',
    october: '十月',
    november: '十一月',
    december: '十二月',
    promoCode: '促销代码',
    promoCodeSendMail: '此促销代码已发送到您的电子邮件',
    promoCodeCopy: '复制促销代码',
    searchPlaceholder: '开始搜索。',
    searchGame: '游戏搜索',
    searchGameDescription: `完全或部分输入游戏名称。`,
    searchGameDescription_2: '必须正确输入关键词',
    not_found: '没找到',
    information: '信息',
    success: '成功',
    error: '错误',
    copy_wechat: '将微信 ID 复制到剪贴板',
    close: '关闭',
    view_details: '查看详情',
    detail: '详情',
    price: '价格',
    id: 'ID',
    name: '名称',
    summ: '总计',
    date: '日期',
    conclusion: '结论',
    product: '产品',
    description: '描述',
    all_category: '所有类别',
    basket_bets: '投注单',
    not_coins: '硬币不够...',
    excellent: '完美',
    excellent_description: `根据你的选项，我们猜你会乐于收下我们准备好的额外奖励！点击“领取”查看具体金额`,
    bonuses_to_account: '<span>1000</span>个赌币已到账',
    bonuses_to_account_start: '我们刚刚将 ',
    bonuses_to_account_end: ' 存入您的账户！',
    continue_luck: `为了提升你的体验，我们还为你准备好了登录名和密码`,

    filter: '过滤器',
    choose: '选择',
    choose_user_type: '选择您的身份',

    bet_slip: '投注单',
    bet_types: '投注类型',
    my_bets: '我的投注',
    loader: '加载',
    clear: '清除',
    over_all_odds: '总赔率',
    stake: '赌注',
    accumulator: '累计',
    singles: '单注',
    system_bet: '系统赌注',
    settings_stake_info: '设置投注单赌注以更改',
    stake_amount: '投注金额',
    possible_win: '可能的胜利',
    agree_bet: '同意改变系数',
    bet: '投注',
    place_bet: '下注',
    place_bid: '出价',
    unknown: '未知',
    bets_not_accepted: '不接受此投注',
    unlock: '解锁',
    home_route: '首页',
    experts_route: '專家',
    sports_route: '体育',
    'match-centre_route': '赛事中心',
    analytics_route: '赛事预测',
    tournament_route: '赛事',
    school_route: '博彩学校',
    news_route: '新闻',
    game_route: '娱乐场',
    forCasts_route: '娱乐场',
    games_route: '虚拟体育',
    blog_route: '博客',
    sport_route: '体育',
    category_route: '类别',
    slots_route: '插槽',
    item_route: '更多细节',
    shop_route: '商店',
    leaderboard_route: '排行榜',
    //Errors form
    required_field: '必须填写',
    correct_email: '输入有效的电子邮件地址',
    correct_email_user_id: '输入有效的电子邮件地址或用户 ID ',
    correct_login: '输入有效的登录名',
    correct_phone: '输入有效电话',
    correct_password: '输入有效密码',
    correct_password_repeat: `密码不匹配`,
    correct_length: `输入正确的长度`,
    menu_name: '菜单',
    coupon_name: '优惠券',
    best_slit_name: '最佳滑动',

    'User not found': '未找到用户',
    'Code is not valid': '代码无效',
    'Email Used': '使用的电子邮件',
    'Wrong password': '密码错误',
    'User already exists': '用户已存在',
    'Network Error': '网络错误',
    'Express Must be 2 or more bets': `快车 必须是 2 个或更多投注`,

    error_page: '页面错误',
    profile_route: '帐户',
    main_route: '主页',
    'edit-profile_route': '编辑简介',
    'account-profile_route': '账户',
    'edit-info_route': '编辑信息',
    'edit-data_route': '编辑数据',
    'change-password_route': '更改密码',
    'payment-history_route': '资金流水',
    'betting-history_route': '投注历史',
    'bet-dictionary_route': '词典',
    lebao_foundation_route: '乐宝吧基金会',
    works_route: '作品',
    'deposit-history_route': '存款',
    open_for: '开放: ',
    read_more: '更多信息',
    your_bet: '您的投注',
    blocked: '受阻',
    BET_IN_PROGRESS: '赌注在计算中',
    BET_IS_RETURN: '退款',
    BET_IS_REFUND_ERROR: '退款',
    HALF_REFUND: `一半的錢回來了`,
    BET_IS_WIN: '赌注胜利',
    BET_IS_LOSE: '赌注失败',
    BET_IS_CANCELED: '赌注被取消',
    BET_IS_DELETED: '赌注被删除',
    possible_winnings: '可能获胜',
    status: '状况',
    section: '部分',
    cancel: '取消',
    ok: '好的',
    apply: '申请',
    next: '下一个',
    save: '节省',
    save_editor: '保存编辑',
    save_password: '保存密码',
    change_info: '更改信息',
    express: '蓄能器',
    odinar: '单次投注',
    result: '结果',
    event: '活动',
    event_status: '事件状态',
    win: '赢',
    express_game_title: '快速游戏视图',
    W1: '主队赢',
    W1_way: '主队赢 (方式)',
    X: '平局',
    W2: '客队赢',
    W2_way: '客队赢 (方式)',
    '1X': '主队赢或平局',
    '12': '主队赢或客队赢',
    '2X': '客队赢或平局',
    total: '总计',
    total_over: '总计 撤销',
    win_and_total: '胜率和总胜率',
    total_kef: '总系数',
    handicap: '让分',
    handicap_sets: '残疾人套装',
    full_battle: '全面战斗、Yes-kf、No-kf',
    team_wins: '团队获胜',
    betsword: '投注术语',
    betsword_discriptions:
      '浏览赌博世界可能会因其独特的术语而变得具有挑战性。我们的投注术语在此为您指导。知识就是力量，我们的目标是赋予每一个投注者权力！',
    bonuses: '乐宝经验系统',
    bonuses_discriptions:
      '该系统允许您分析玩家的活动和当前进度。 XP如何被记入-玩家在网站上执行积极的行动，"对于每个动作，他们都会获得经验值。',
    bonuses_route: '奖金',
    'privacy-policy_route': '隐私政策',
    'gamble-responsible_route': '博彩负责',
    'terms-and-conditions_route': '条款条约',
    faq_route: '常见问题',
    // Ceo
    page_title_not_found: 'NotFound',
    page_description_not_found: 'NotFound',
    page_title_home: '乐宝吧是一个社交游戏平台',
    page_description_home:
      '乐宝吧是一个社交游戏平台，您可以在其中对全球范围内的真实比赛进行体育投注 在乐宝吧 上您可以赚取 乐宝币您可以在我们的商店消费或购买彩票抽奖 获得免费提示 预测攀登排行榜或学习和练习体育博彩!',
    page_title_blog: '体育博客',
    page_description_blog: '体育新闻 ，博彩学校 ',
    page_title_blog_news: '新闻',
    page_description_blog_news:
      '了解最新最劲爆的体育信息和行业消息 尽在乐宝吧新闻！\n' +
      '在这里您可以看到您感兴趣的所有体育新闻 行业内幕消息分析分享 以及关于乐宝吧的最新优惠活动详情!',
    page_title_blog_school: '博彩学校',
    school_category: '学校',
    shop: '购物',
    analytic: '解析',
    page_description_blog_school:
      '乐宝吧博彩学校 为您提供最专业的博彩学习指导 辅助您理解更多关于博彩的专业知识竞技规律 掌握瞬息万变的当下局势 以便做出最有利于您的决定 达到利益最大化！',
    page_title_sport: '运动',
    page_description_sport: '赛事中心',
    page_title_sport_match_center: ' 赛事中心 ',
    page_description_sport_match_center: '只需选择体育，联赛和2支球队，信息页面将独立生成',
    page_title_sport_analytics: '事件分析',
    page_description_sport_analytics: ' ',
    page_title_shop: '商店',
    page_description_shop: '下注，积累投注币，来我们的礼品店领取奖品',
    page_title_user: '个人账户',
    page_description_user: '余额, 编辑数据, 修改密码, 支付历史, 投注历史',
    page_title_user_edit: '帐号设定',
    page_description_user_edit: '修改帐户设置',
    page_title_user_edit_password: '设置密码',
    page_description_user_edit_password: '修改密码',
    page_title_betsword: '贝特斯福德',
    page_description_betsword: '博彩术语 学校投注 亚洲盘 是什么',
    page_title_bonuses: '每个用户获得奖金',
    page_description_bonuses: '如何获取经验值 (XP) - 玩家执行积极的操作，对每个操作玩家获得经验值',
    page_title_games: '虚拟体育',
    page_description_games:
      '这个分析部分不仅会为您提供最近比赛的结果，还会为您提供长达10年的以往比赛的详细统计数据',
    page_title_faq: '经常问的问题',
    page_description_faq: '为什么选择线上游戏？存款安全吗？什么是新人奖金?',
    page_title_leaderboard_title: '玩家排名',
    page_title_leaderboard_description:
      '在这里 您不仅可以看到最近各大体育赛事的比赛的结果，还可以找到过去10年的各种重要比赛的详细数据！ 你只需要输入体育项目，联赛名称和参与比赛的球队或运动员名称，信息页面边疆立刻呈现在您的眼前！',
    page_title_privacy_policy: '对于保存客户资料方面，乐宝吧一向采取最谨慎的态度及工作程序作处理',
    page_title_gamble_responsible:
      '注意 博彩仅是一种娱乐形式 对于大多数人来说 .很容易享受到博彩游戏的乐趣',
    page_title_terms_and_conditions: '本公司只接受年满18岁之注册用户',
    footer_text: `乐宝吧是一个社交游戏平台，您可以在这里对全球范围内的真实比赛进行体育投注。
			在乐宝吧，您可以赚取乐宝币（赌币）、在我们的商店消费或购买彩票抽奖；平台还会为您提供每场比赛的预测，展示排行榜，帮助您学习并练习体育投注 !
	`,
    footer_label: '乐宝吧©',
    bonus_title: '乐宝体验系统',
    bonus_description: `T该系统允许您分析玩家的活动和当前进度。 XP如何被记入-玩家在网站上执行积极的行动, 
		对于每个动作，他们都会获得经验值。`,
    betting_school: '博彩学校',
    balance: '余额',
    joined: '加入时间',
    birth: '出生日期',
    county: '国家',
    collect: '收集',
    collect_free: '收集免费金币',
    daily_bonuses: '别忘记每天来签到，每天还可额外获得<span>500</span>个赌币！ ',
    daily_bonuses_start: '不要错过每天的 ',
    daily_bonuses_end: ' 只要你每天回来报到！',
    xp_next_star: '到下一个明星',
    level: '水平',
    username: '用户名称',
    old_password: '旧密码',
    new_password: '新密码',
    repeat_password: '重复新密码',
    fill_field: '填写字段',
    minimum_characters: '最小字符数',
    maximum_characters: '最大字符数',
    passwords_dont_match: '密码不匹配',
    date_from: '数据来自',
    date_to: '日期至',
    corrected_date: '输入正确的日期',
    show_all: '全部展示',
    hidden: '隐藏',
    unRoll: '展开',
    roll: '滚动',
    only_deposits: '只有存款',
    deposit: '按金',
    only_witdrawals: '只有提款',
    withdrawal: '退出',
    status_win: '地位赢',
    status_loss: '状态丢失',
    copy_link: '复制链接',

    demo_user: 'Demo用户',
    regular_user: '普通用户',
    demo_user_info_mobile:
      '如果您不愿透露隐私或嫌麻烦，可以Demo用户身份立即开始下注。还有每日积分待您领取！',
    regular_user_info_mobile:
      '普通用户：通过邮箱注册有诸多好处——您可以领取每日积分，并在奖励系统领取礼品，更多功能等你来发掘！',

    demo_user_info: '如果您还没有做好决定，选择Demo用户以了解网站基本功能。还有每日积分待您领取！',
    regular_user_info:
      '普通用户：通过邮箱注册有诸多好处——您可以领取每日积分，并在奖励系统领取礼品，更多功能等你来发掘！',

    empty_basket: `您的投注单是空的。添加赛事或选择以下选项之一
		以下选项`,
    expertMoreExperience: '您需要更多的经验才能接触到这位专家',
    //deposit payment
    depositDescription: '选择您要为账户充值的支付系统：',
    alipay: '阿里支付',
    weChatPlay: '微信支付',
    unionPay: '银联支付',
    //chat
    sendMessage: '在此输入信息...',
    weOnline: '我们在线，请给我们写信',
    haveQuestion: `有问题？`,
    downloadDialog: '下载对话框',
    attachFile: '附加文件',
    errorConnect: '连接错误',
    rateSupport: `您如何评价我们的支持？`,
    position: `等级`,
    user: `用户`,
    user_position: `用户位置`,
    rating: `排名`,

    //Referral
    referral_title: `转介计划`,
    referral_work: `工作原理:`,
    referral_invite: `邀请:`,
    referral_invite_description: `与朋友分享您的独特推荐链接`,

    referral_earn: `赚取:`,
    referral_earn_description: `每位注册并购物的朋友都可获得奖励 3`,

    referral_link: `推荐链接:`,
    //quiz
    betcoins: '投注币',
    changeLang: '选择测验语言。',
    rewardTitle: `恭喜您 </br> 您获得了每日奖励`,
    // Сообщения
    questionOneVarian1: `直想试试赌博，却又不知如何开始？		`,
    questionTwoVarian1: `直想试试，却有点担心？		`,
    questionThreeVarian1: `想要每天都获得免费积分来一试运气吗？`,
    finalTextVariant1: '谨慎总是好的，但你会得到很好的照顾！',
    finalText2Variant1:
      '谨慎是件好事，但是在这里，你完全不必担心！我们的团队将全力保障您的隐私，同时为您提供最为刺激的体验。在这里，你可以合法、安全地享受游戏，同时在注册时立刻获得1000个积分，然后每天获得500个！',
    finalText3Variant1: `如果您还需要更多时间，博彩学校始终是了解乐宝吧的最佳途径。快将您收到的赌币大礼包物尽其用，来了解博彩世界的神奇吧！🎁	`,

    questionOneVariant2: ' 你有没有尝试过体育博彩？',
    questionTwoVariant2: '想和众多同好一起进步吗？',
    questionThreeVariant2: '想不想当第一，赢取丰厚奖励？',
    finalTextVariant2:
      '我们发现你已经做好准备！我们的博彩学校永远是开启这段旅程的最佳起点。请收下1000个赌币作为我们的见面礼！别忘了每天回来签到，领取您的每日500赌币！',
    finalText2Variant2: '当您感到更自信时，就用我们为您准备的赌币试试您学到的新技能吧！🎁',

    questionOneVariant3: ' 你尝试过体育博彩吗？',
    questionTwoVariant3: '想获取体育比赛的精准预测吗？',
    questionThreeVariant3: '想不想每天都赢得免费积分，然后一试运气？',

    finalTextVariant3:
      '感谢您的时间！我们发现您已经有一些经验。请收下1000个赌币作为我们的见面礼 🎁	',
    finalText2Variant3: `别忘了每天回来签到，领取您的每日500赌币！`,
    finalText3Variant3: '与此同时，你随时可以通过我们的博彩学校来变得更强！'
  }
}
