import { FC } from 'react'

import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { IPropCheckbox } from '../form.interface'

import styles from './Checkbox.module.scss'



export const Checkbox: FC<IPropCheckbox> = ({
  className,
  checkboxClassName,
  title,
  link,
  url,
  onChange,
  checked,
  name,
  id,
  defaultChecked,
  disabled,
  modifier
}) => {
  const mod = {
    full: false,
    center: false
  }

  modifier?.forEach(str => {
    mod[str] = true
  })

  const wordsToHighlight = ['terms', 'conditions']

  const renderHighlightedText = (text: string, highlights: string[]) => {
    const regex = new RegExp(`(${highlights.join('|')})`, 'gi')
    return text.split(regex).map((part, index) =>
      highlights.some(word => word.toLowerCase() === part.toLowerCase()) ? (
        <span key={index} className={styles.highlight}>
          {part}
        </span>
      ) : (
        part
      )
    )
  }

  return (
    <label
      className={clsx(
        className,
        styles.checkbox,
        disabled && styles.disabled,
        mod.full && styles.fullWidth,
        mod.center && styles.centerPosition
      )}>
      <input
        className={styles.checkbox__input}
        type='checkbox'
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        name={name}
        defaultChecked={defaultChecked}
        id={id}
      />
      <span
        className={clsx(styles.checkbox__check, checkboxClassName, !checked && styles.disabled)}
      />

      {title && (
        <div className={styles.checkbox__textBlock}>
          <span className={styles.checkbox__text}>
            {renderHighlightedText(title, wordsToHighlight)}
          </span>

          {link && (
            <Link to={url || `#`} className={styles.checkbox__link} target='_blank'>
              {link}
            </Link>
          )}
        </div>
      )}
    </label>
  )
}
